import React from "react";

import "./App.css";

export const App = () => {
    const showRedirectLink = window.location.pathname !== "/error";

    return (
        <div className="app">
            <div className="app__info-box">
            <div className="app__title">Tato adresa není momentálně dostupná.</div>
            <div className="app__title-translation">This address is currently unavailable.</div>
            {showRedirectLink && (
                <a href="http://pavelpo.cz/" className="app__link">
                    <div className="app__link-title">Přejít na hlavní stránku.</div>
                    <div className="app__link-title-translation">Go to the main page.</div>
                </a>
            )}        
            </div>
        </div>
    );
};
